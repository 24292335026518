












import ContentCategory from "./task_types/content_category.vue"
import Summarization from "./task_types/summarization.vue"
import ExpertiseContent from "./task_types/expertise_content.vue"

export default {
  components: { ContentCategory, Summarization, ExpertiseContent }

  props:
    tasks: Array

  methods:
    taskComponent: (task)->
      switch task.type
        when "content_category" then 'ContentCategory'
        when "summarization" then 'Summarization'
        when 'expertise_content' then 'ExpertiseContent'
}
