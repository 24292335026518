




















































































































export default {

  props:
    task: Object

  data: ->
    showRawResponse: false
    uuid: crypto.randomUUID()

  computed:
    categorized: ->
      @task.status == 'success' && (@task.category=='positive' || @task.category=='negative')

    clearResponse: ->
      switch
        when @showRawResponse then @task.raw_response
        else (@task.thoughts || '').replace(/\*{2}([^*]+)\*{2}/g, '<i class="input-group__hint input-group__hint--big">$1</i>')

    categoryTitle: ->
      switch
        when @task.status!='new' && @categorized then @task.category_text
        else @task.title

    iconClass: ->
      switch
        when @task.category=='positive' then 'fc-item__icon--ai__positive'
        when @task.category=='negative' then 'fc-item__icon--ai__negative'
        else
          switch
            when @task.status == 'success' then 'fc-item__icon--ai__success'
            when @task.status == 'error' then 'fc-item__icon--ai__error'
            else ''
}
