














































export default {
  props:
    task: Object

  computed:
    taskTitle: ->
      if @task.p_compression != ''
        @task.p_compression
      else
        @task.title

}

