





import AiTasks from "./ai_tasks.vue"

DATA_MIXIN = {
  data: ->
    tasks: []
}

export default {
  mixins: [DATA_MIXIN]

  components: { AiTasks }
}
