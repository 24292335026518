import { render, staticRenderFns } from "./summarization.vue?vue&type=template&id=c640beae&"
import script from "./summarization.vue?vue&type=script&lang=coffee&"
export * from "./summarization.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports