



























export default {
  name: 'ModalDialog'

  props:
    maxHeighted: Boolean
    hideOverflow:
      type: Boolean
      default: true
  data: ->
    show: false

  methods:
    closeModal: ->
      @show = false
      $('html').css('overflow', 'auto') if @hideOverflow
      @$emit('close')

    openModal: ->
      @show = true
      $('html').css('overflow', 'hidden') if @hideOverflow
}
