























































































export default {
  props:
    task: Object

  data: ->
    showRawResponse: false
    uuid: crypto.randomUUID()

  computed:
    parsed: ->
      @task.status == 'success' && !!@task.parsed

    clearResponse: ->
      if @showRawResponse
        @task.raw_response
      else
        (@task.clear_response || '').replace(/\*{2}([^*]+)\*{2}/g, '<i class="input-group__hint input-group__hint--big">$1</i>')

    taskTitle: ->
      if @parsed
        'Итоговый балл: ' + @task.result_value
      else
        @task.title

}
